import React, { useState, useEffect } from 'react';
import 'assets/scss/Web/Sell-website/Homepage/homepage.scss'
import SaveTime from 'assets/images/Sell-Website/save-time.png'
import SaveMoney from 'assets/images/Sell-Website/save-money.png'
import Professional from 'assets/images/Sell-Website/professional.png'
import Banner1 from 'assets/images/Sell-Website/banner-1.png'
import Banner2 from 'assets/images/Sell-Website/banner-2.png'
import Banner3 from 'assets/images/Sell-Website/banner-3.png'
import Car from 'assets/svg/car-svgrepo-com.svg'
import Price from 'assets/svg/price-tag-offer-badge-svgrepo-com.svg'
import Phone from 'assets/svg/phone-svgrepo-com (1).svg'
import Day from 'assets/svg/day-calendar-svgrepo-com.svg'
import Time from 'assets/svg/time1-svgrepo-com.svg'
import Connect from 'assets/svg/arrow-left-right-svgrepo-com.svg'
import Toyota from 'assets/images/xe-toyota-vios-e-phien-ban-1-5-cvt-1792103j24390.webp'
import Mis from 'assets/images/z4854715952864_91888fbc4fd88493a301059ad45ca768.jpg'
import Car6 from 'assets/images/accent.webp'
import Car7 from 'assets/images/inovar.jpeg'
import Car8 from 'assets/images/fortune.webp'
import Car3 from 'assets/images/Mazda32020VnE993047211573621051jpg-1631963909.jpg'
import Car4 from 'assets/images/nien-han-xe-ban-tai-2.jpg'
import Car5 from 'assets/images/thumb-nissan-navara-1.webp'
import ImageServices7 from 'assets/images/du-lich-lang-giang.jpeg'
import ImageServices8 from 'assets/images/du-lich-luc-nam.jpeg'
import ImageServices3 from 'assets/images/du-lich-luc-ngan.jpeg'
import ImageServices4 from 'assets/images/du-lich-son-dong.jpeg'
import ImageServices5 from 'assets/images/du-lich-tan-yen.jpeg'
import ImageServices6 from 'assets/images/du-lich-yen-dung.jpeg'
import ImageServices9 from 'assets/images/du-lich-yen-the.jpeg'
import ImageServices1 from 'assets/images/Khu-bao-ton-thien-nhien-Tay-Tien-Tu.webp'
import ImageServices2 from 'assets/images/du-lich-lang-giang.jpeg'
import Image1 from 'assets/images/136958118051a2267c8942b.jpg'
import Image2 from 'assets/images/99158842102074008420157416933532148800421888o-15912480094981679273639-0718.jpg'
import Image3 from 'assets/images/Khu-bao-ton-thien-nhien-Tay-Tien-Tu.webp'
import Image4 from 'assets/images/cao-nguyen-dong-cao-2.webp'
import Image5 from 'assets/images/chua-phat-tich-ngoi-co-tu-hon-1000-nam-tuoi-o-bac-ninh-01-1689124541.jpg'
import Review1 from 'assets/images/Sell-Website/review-1.png'
import Review2 from 'assets/images/Sell-Website/review-2.png'
import Review3 from 'assets/images/Sell-Website/review-3.png'
import Review4 from 'assets/images/Sell-Website/review-4.png'
import Save from 'assets/svg/save-money-cash-savings-money-reserve-svgrepo-com.svg'
import Business from 'assets/svg/business-development-frame-svgrepo-com.svg'
import Like from 'assets/svg/like-1385-svgrepo-com.svg'
import Safe from 'assets/svg/safe-shield-2-svgrepo-com.svg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { fetchPostIP } from 'Apis'
const Index = () => {
    const [activeID, setActiveID] = useState(0);
    const [buttonHover, setButtonHover] = useState(false);
    const data = [
        {
            id: 0,
            header: 'BẠN MUỐN BAO XE?',
            body: 'Bạn là người hướng nội, hay bạn không thích bị ai quấy rầy trong quá trình di chuyển hoặc thậm trí bạn chỉ muốn đi 1 mình. Đừng lo, ngoài dịch vụ xe ghép ra, khách hàng có thể bao xe .',
            colour: '#00000090',
            video: process.env.PUBLIC_URL + 'slide-3.mp4'
        },
        {
            id: 1,
            header: 'PHƯƠNG TIỆN HIỆN ĐẠI',
            body: 'Hiện nay với sự tăng trưởng nhanh chóng của xe ô tô và hạ tầng giao thông, việc di chuyển bằng xe ô tô mới nội thất hiện đại sẽ đem đến cho khách hàng một trải nghiệm tuyệt vời nhất.',
            colour: '#00000090',
            video: process.env.PUBLIC_URL + 'slide-1.mp4'
        },
        {
            id: 2,
            header: 'ĐÓN VÀ TRẢ',
            body: 'Với khí hậu và thời tiết ở miền Bắc ngày nay là 1 trở ngại lớn khiến chúng ta ngại ra đường. Đừng lo chỉ cần 1 cuộc gọi của khách hàng dù nắng hay mưa, dù xa hay gần, chúng tôi luôn có mặt để giúp đỡ bạn giải quyết vấn đề di chuyển',
            colour: '#2c2c2c90',
            video: process.env.PUBLIC_URL + 'slide-2.mp4'
        },
        {
            id: 3,
            header: 'LUÔN HOẠT ĐỘNG 24/7',
            body: 'Chúng tôi luôn sẵn sàng trở bạn đi đến bất cứ đâu tại Bắc Giang - Bắc Ninh - Hà Nội. Liên hệ ngay cho chúng tôi để chúng tôi đem đến cho bạn dịch vụ tốt nhất',
            colour: '#00000090',
            video: process.env.PUBLIC_URL + 'slide-5.mp4'
        }
    ];
    const slides = [
        {
            city: 'Paris',
            country: 'France',
            img: Banner1,
        },
        {
            city: 'Singapore',
            img: Banner2,
        },
        {
            city: 'Prague',
            country: 'Czech Republic',
            img: Banner3,
        },
    ];
    const slides_location = [
        {
            city: 'Tây Yên Tử',
            country: 'Bắc Giang',
            img: ImageServices1,
        },
        {
            city: 'Cánh Đồng Cỏ Xanh',
            country: 'Bắc Giang',
            img: ImageServices2,
        },
        {
            city: 'Lục Ngạn',
            country: 'Bắc Giang',
            img: ImageServices3,
        },
        {
            city: 'Sơn Động',
            country: 'Bắc Giang',
            img: ImageServices4,
        },
        {
            city: 'Tân Yên',
            country: 'Bắc Giang',
            img: ImageServices5,
        },
        {
            city: 'Yên Dũng',
            country: 'Bắc Giang',
            img: ImageServices6,
        },
        {
            city: 'Lạng Giang',
            country: 'Bắc Giang',
            img: ImageServices7,
        },
        {
            city: 'Lục Nam',
            country: 'Bắc Giang',
            img: ImageServices8,
        },
        {
            city: 'Yên Thế',
            country: 'Bắc Giang',
            img: ImageServices9,
        },
    ];
    const [initialLoad, setInitialLoad] = useState(true)
    useEffect(() => {
        const interval = setInterval(() => {
            setInitialLoad(false)
            setActiveID((prevID) => (prevID === data.length - 1 ? 0 : prevID + 1));
        }, 5000);
        return () => clearInterval(interval);

    }, []);
    useEffect(() => {
        if (!initialLoad) {
            const panelElements = document.querySelectorAll('.panel');
            panelElements.forEach((panel, index) => {
                panel.classList.toggle('active', index === activeID); // Thêm hoặc loại bỏ lớp active cho panel tương ứng
            });
        }
    }, [activeID, initialLoad]);
    useEffect(() => {
        // fetchPostIP()
        //     .then()
        //     .catch(error => {
        //         console.log(error)
        //     })
    }, []);
    const IMAGE_PARTS = 4;
    const AUTOCHANGE_TIME = 5000;

    const [activeSlide, setActiveSlide] = useState(0);
    const [prevSlide, setPrevSlide] = useState(-1);
    const [sliderReady, setSliderReady] = useState(true);
    let changeTimeout

    const [activeSlideBanner, setActiveSlideBanner] = useState(0);
    const [prevSlideBanner, setPrevSlideBanner] = useState(-1);
    const [sliderReadyBanner, setSliderReadyBanner] = useState(true);
    let changeTimeoutBanner
    useEffect(() => {
        changeTimeout = setTimeout(() => {
            changeSlides(1);
        }, AUTOCHANGE_TIME);
        return () => {
            clearTimeout(changeTimeout);
        };
    }, [activeSlide]);

    useEffect(() => {
        changeTimeoutBanner = setTimeout(() => {
            changeSlidesBanner(1);
        }, AUTOCHANGE_TIME);
        return () => {
            clearTimeout(changeTimeoutBanner);
        };
    }, [activeSlideBanner]);
    const changeSlides = (change) => {
        const length = slides.length;
        const prevSlideValue = activeSlide;
        let newActiveSlide = prevSlideValue + change;
        if (newActiveSlide < 0) newActiveSlide = length - 1;
        if (newActiveSlide >= length) newActiveSlide = 0;
        setActiveSlide(newActiveSlide);
        setPrevSlide(prevSlideValue);
    };
    const changeSlidesBanner = (change) => {
        const length = slides_location.length;
        const prevSlideValueBanner = activeSlideBanner;
        let newActiveSlideBanner = prevSlideValueBanner + change;
        if (newActiveSlideBanner < 0) newActiveSlideBanner = length - 1;
        if (newActiveSlideBanner >= length) newActiveSlideBanner = 0;
        setActiveSlideBanner(newActiveSlideBanner);
        setPrevSlideBanner(prevSlideValueBanner);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (<>
        <div className='section-sell-website-homepage'>
            <div className='section-sell-website-slide' id='trangchu'>
                <section className="wrapper">
                    <div className="selectors">
                        {data.map((item) => (
                            <div
                                key={item.id}
                                className={`selector${item.id === activeID ? ' active' : ''}`}
                                onClick={() => setActiveID(item.id)}
                            ></div>
                        ))}
                    </div>

                    {data.map((item) => (
                        <aside key={item.id} className={`panel${item.id === activeID ? ' active' : ''}`} style={{ backgroundColor: item.colour }}>
                            <h2 className="panel-header play-bold">{item.header}</h2>
                            <p className="panel-info play-regular">{item.body}</p>
                            <video
                                className="panel-video"
                                autoPlay={true}
                                loop
                                muted
                                playsInline
                                src={item.video}
                            ></video>
                                <a href="tel:0374216188"
                                    className="panel-button"
                                    style={{ color: buttonHover ? item.colour : '#ffffff', textDecoration: 'unset' }}
                                >
                                    Đặt xe ngay
                                </a>
                        </aside>
                    ))}

                </section>
            </div>
            <div className='section-sell-website-question' id="dichvu">
                <div className='section-sell-website-question-above'>
                    <style>{`.section-sell-website-question-title {
                &::after {
                    background: url('/line.svg') center bottom no-repeat;
                }
            }`}</style>
                    <div className='section-sell-website-question-title play-bold'>Vì sao nên chọn dịch vụ chúng tôi? </div>
                    <div className='section-sell-website-question-subtitle play-regular'>Không phải ngẫu nhiên dịch vụ đi xe ghép trở nên phổ biến và được nhiều người tin dùng đến vậy. Với nhiều lợi ích tuyệt vời, đi xe ghép có nhiều ưu điểm vượt trội so với các loại hình di chuyển khác</div>
                </div>
                <div className='row section-sell-website-question-below'>
                    <div className='col-md-7 section-sell-website-question-below-left'>
                        <div className='section-sell-website-question-below-left-box'>
                            <div className='col-md-5 section-sell-website-question-below-left-img'><img src={SaveTime} alt="123" width={250} height={150} /></div>
                            <div className='col-md-7 section-sell-website-question-below-left-content'>
                                <div className='section-sell-website-question-below-left-title play-bold'>
                                    Tiết kiệm thời gian
                                </div>
                                <div className='section-sell-website-question-below-left-subtitle play-regular'>
                                    Không mất thời gian chờ đợi tại các điểm đón như xe buýt hoặc xe khách. Chúng tôi sẽ nhanh chóng có mặt tại nhà hoặc địa điểm bạn cung cấp để không làm lỡ kế hoạch của bạn.
                                </div>
                            </div>
                        </div>
                        <div className='section-sell-website-question-below-left-box'>
                            <div className='col-md-5 section-sell-website-question-below-left-img'><img src={SaveMoney} alt="123" width={250} height={150} /></div>
                            <div className='col-md-7 section-sell-website-question-below-left-content'>
                                <div className='section-sell-website-question-below-left-title play-bold'>
                                    Tiết kiệm chi phí
                                </div>
                                <div className='section-sell-website-question-below-left-subtitle play-regular'>
                                    Tiết kiệm chi phí đi xe và không phải mất thêm những phụ phí khác. Việc share tiền với những người đi chung và tốn tiền thuê thêm xe ôm giúp bạn giảm được 40% chi phí so với cách thức truyền thống.
                                </div>
                            </div>
                        </div>
                        <div className='section-sell-website-question-below-left-box'>
                            <div className='col-md-5 section-sell-website-question-below-left-img'><img src={Professional} alt="123" width={250} height={150} /></div>
                            <div className='col-md-7 section-sell-website-question-below-left-content'>
                                <div className='section-sell-website-question-below-left-title play-bold'>
                                    Trở nên tiện dụng
                                </div>
                                <div className='section-sell-website-question-below-left-subtitle play-regular'>
                                    Với sự tăng trưởng nhanh chóng của xe ô tô và hạ tầng giao thông, việc di chuyển bằng xe ô tô cá nhân giữa Bắc Giang - Bắc Ninh - Hà Nội sẽ trở nên dễ dàng hơn rất nhiều.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 list-services'>
                        <div className='title-services'>Các dịch vụ của chúng tôi</div>
                        <div className='content-services'>
                            <img src={Car} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Xe đưa đón tận nơi trả tận nhà. nhanh chóng . nhiệt tình</div>
                        </div>
                        <div className='content-services'>
                            <img src={Price} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Giá ghép chỉ từ 200.000 VNĐ.</div>
                        </div>
                        <div className='content-services'>
                            <img src={Price} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Giá bao xe 500.000 VNĐ trở lên tuỳ điểm.</div>
                        </div>
                        <div className='content-services'>
                            <img src={Price} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Giá vận chuyển đồ từ 150.000 VNĐ.</div>
                        </div>
                        <div className='content-services'>
                            <div className='services-icon-content'>Bắc Giang</div>
                            <img src={Connect} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Bắc Ninh</div>
                            <img src={Connect} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Hà Nội</div>
                        </div>
                        <div className='content-services'>
                            <img src={Phone} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>0374216188 </div>
                        </div>
                        <div className='content-services'>
                            <img src={Day} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Các ngày trong tuần.</div>
                        </div>
                        <div className='content-services'>
                            <img src={Time} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Bắt đầu từ 4h đến 21h30 hằng ngày.</div>
                        </div>
                        <div className='content-services'>
                            <img src={Car} className='services-icon' alt="Logo" />
                            <div className='services-icon-content'>Đón và trả tận nơi.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-sell-website-banner'>
                <div className={`slider ${sliderReady ? 's--ready' : ''}`}>
                    <div className="slider__slides">
                        {slides.map((slide, index) => (
                            <div
                                className={`slider__slide ${activeSlide === index ? 's--active' : ''} ${prevSlide === index ? 's--prev' : ''}`}
                                key={slide.city}
                            >
                                <div className="slider__slide-parts">
                                    {[...Array(IMAGE_PARTS)].map((_, i) => (
                                        <div className="slider__slide-part" key={i}>
                                            <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="slider__control" onClick={() => changeSlides(-1)} />
                    <div className="slider__control slider__control--right" onClick={() => changeSlides(1)} />
                </div>
            </div>
            <div className='section-sell-website-question' id="banggia">
                <div className='section-sell-website-question-above'>
                
                    <div className='section-sell-website-question-title play-bold'>bảng báo giá xe ghép - bao xe</div>
                    <div className='section-sell-website-question-subtitle play-regular'>Báo giá dưới đây với mục đích cho khách hàng tham khảo, thông tin báo giá chi tiết sẽ được gửi đến khách hàng sau khi trao đổi cụ thể.</div>
                </div>
                <div className='section-sell-website-price'>
                    <div className='col-md-6 section-sell-website-price-box'>
                        <div className='section-sell-website-price-above' style={{ background: 'grey' }}>
                            <div className='section-sell-website-price-above-title play-bold'>Xe ghép</div>
                            <div className='section-sell-website-price-above-title play-bold'>Bắc Giang - Bắc Ninh - Hà Nội</div>
                            <div className='section-sell-website-price-above-button play-regular'>( Tuỳ từng địa điểm - thời gian )</div>
                        </div>
                        <div className='section-sell-website-price-below'>
                            <div className='content-table-prices' >
                                <div className='location-table'>Bắc Giang - Hà Nội</div>
                                <div className='price-table'>200.000 VND</div>
                            </div>
                            <div className='content-table-prices' >
                                <div className='location-table'>Bắc Giang - Bắc Ninh</div>
                                <div className='price-table'>150.000 VND</div>
                            </div>
                            <div className='content-table-prices' >
                                <div className='location-table'>Bắc Ninh - Hà Nội</div>
                                <div className='price-table'>200.000 VND</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 section-sell-website-price-box'>
                        <div className='section-sell-website-price-above' style={{ background: 'grey' }}>
                            <div className='section-sell-website-price-above-title play-bold'>Bao xe</div>
                            <div className='section-sell-website-price-above-title play-bold'>Bắc Giang - Bắc Ninh - Hà Nội</div>
                            <div className='section-sell-website-price-above-button play-regular'>( Tuỳ từng địa điểm - thời gian )</div>
                        </div>
                        <div className='section-sell-website-price-below'>
                            <div className='content-table-prices' >
                                <div className='location-table'>TP Bắc Giang - TP Hà Nội</div>
                                <div className='price-table'>500.000 VND</div>
                            </div>
                            <div className='content-table-prices' >
                                <div className='location-table'>TP Bắc Giang - TP Bắc Ninh</div>
                                <div className='price-table'>200.000 VND</div>
                            </div>
                            <div className='content-table-prices' >
                                <div className='location-table'>TP Bắc Ninh - TP Hà Nội</div>
                                <div className='price-table'>350.000 VND</div>
                            </div>
                            <div className='content-table-prices' >
                                <div className='location-table'>Khách đi tỉnh</div>
                                <div className='price-table'>9.000 VND / km</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-sell-website-question'>
                <div className='section-sell-website-question-above'>
                
                    <div className='section-sell-website-question-title play-bold'>CÁC LOẠI XE ĐƯỢC CUNG CẤP</div>
                    <div className='section-sell-website-question-subtitle play-regular'>Chúng tôi cung cấp các loại xe cao cấp, uy tín, chất lượng nhất nhằm phục vụ quý khách hàng!</div>
                </div>
                <div className='row section-sell-website-car'>
                    <div className='row list-cars'>
                        <Slider {...settings}>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>TOYOTA VIOS 4 chỗ</div>
                                <div className='cars-image-container'>
                                    <img src={Toyota} className='cars-image' alt="1" />
                                </div>
                                {/* <img src={Toyota} className='cars-image'style={{ width: '100%', height: 'auto' }} alt="1" /> */}
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>Mitsubishi 7 chỗ</div>
                                <div className='cars-image-container'>
                                    <img src={Mis} className='cars-image' alt="1" />
                                </div>

                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>MAZDA 4 chỗ</div>
                                <img src={Car3} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="3" />
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>ACCENT 4 chỗ</div>
                                <img src={Car6} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="3" />
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>INOVA 7 chỗ</div>
                                <img src={Car7} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="3" />
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>FORTUNE 7 chỗ</div>
                                <img src={Car8} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="3" />
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>Xe bán tải</div>
                                <img src={Car4} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="4" />
                            </div>
                            <div className='col-12 item-cars'>
                                <div className='item-name-cars'>Xe bán tải</div>
                                <img src={Car5} className='cars-image' style={{ width: '100%', height: 'auto' }} alt="4" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='section-sell-website-question'>
                <div className='section-sell-website-question-above'>
                    
                    <div className='section-sell-website-question-title play-bold'>Danh lam thắng cảnh </div>
                    <div className='section-sell-website-question-subtitle play-regular'>Tham quan, du lịch những nơi chứa đựng nhiều khung cảnh lý tưởng!</div>
                </div>
            </div>
            <div className='section-sell-website-banner'>

                <div className={`slider ${sliderReadyBanner ? 's--ready' : ''}`} style={{ height: '70vh', cursor: 'pointer' }}>
                    <div className="slider__slides">
                        {slides_location.map((slide_location, index) => (
                            <div
                                className={`slider__slide ${activeSlideBanner === index ? 's--active' : ''} ${prevSlideBanner === index ? 's--prev' : ''}`}
                                key={slide_location.city}
                            >
                                <div className="slider__slide-content" style={{ background: 'rgba(0, 0, 0, 0.1)' }}>
                                    <h3 className="slider__slide-subheading">{slide_location.country || slide_location.city}</h3>
                                    <h2 className="slider__slide-heading">
                                        {slide_location.city.split('').map((l, idx) => (
                                            <span key={idx}>{l}</span>
                                        ))}
                                    </h2>
                                </div>
                                <div className="slider__slide-parts">
                                    {[...Array(IMAGE_PARTS)].map((_, i) => (
                                        <div className="slider__slide-part" key={i}>
                                            <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide_location.img})` }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="slider__control" onClick={() => changeSlidesBanner(-1)} />
                    <div className="slider__control slider__control--right" onClick={() => changeSlidesBanner(1)} />
                </div>

            </div>
            <div className='section-sell-website-question'>
                <div className='section-sell-website-question-above'>
                    
                    <div className='trevor'>
                        <div className='row' style={{ margin: '0 auto' }}>
                            <div className='col-12 col-md-4 image-trevor' style={{ padding: 15 }}>
                                <img src={Image2} alt='1' className='responsive-image' />
                                <p className='caption'>Vải thiều - bắc giang</p>
                            </div>
                            <div className='col-12 col-md-8' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', padding: 0 }}>
                                <div className='col-12 col-md-6 image-trevor' style={{ padding: 15 }}>
                                    <img src={Image1} alt='1' className='responsive-image with-margin' />
                                    <p className='caption'>KHU DU LỊCH KHUÔN THẦN – BẮC GIANG</p>
                                </div>
                                <div className='col-12 col-md-6 image-trevor' style={{ padding: 15 }}>
                                    <img src={Image3} alt='1' className='responsive-image with-margin' />
                                    <p className='caption'>khu bảo tồn tây yên tử - bắc giang</p>
                                </div>
                                <div className='col-12 col-md-6 image-trevor' style={{ padding: 15 }}>
                                    <img src={Image4} alt='1' className='responsive-image with-margin' />
                                    <p className='caption'>Đồng Cao - bắc giang</p>
                                </div>
                                <div className='col-12 col-md-6 image-trevor' style={{ padding: 15 }}>
                                    <img src={Image5} alt='1' className='responsive-image with-margin' />
                                    <p className='caption'>CHÙA PHẬT TÍCH - BẮC NINH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-sell-website-question' id="danhgia">
                <div className='section-sell-website-question-above'>
                    
                    <div className='section-sell-website-question-title play-bold'>ĐÁNH GIÁ TỪ KHÁCH HÀNG</div>
                    <div className='section-sell-website-question-subtitle play-regular'>Phản hồi tích cực của khách hàng về dịch vụ của chúng tôi.</div>
                </div>
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="mySwiper"
                >

                    <SwiperSlide>
                        <img src={Review1} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review2} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review3} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review4} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review1} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review2} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review3} alt="1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={Review4} alt="1" />
                    </SwiperSlide>
                </Swiper>

            </div>
            <div className='section-sell-website-question' id="chatluong">
                <div className='section-sell-website-benefit-above'>
                    <div className='section-sell-website-benefit-title play-bold'>XE GHÉP BẮC NINH - BẮC GIANG - HÀ NỘI MANG LẠI CHO KHÁCH HÀNG</div>
                    <div className='row quanlity-under'>
                    <div className='col-6 col-md-3 quanlity-under-content'>
                        <img src={Save} alt='icon' className='quanlity-under-icon'/>
                        <div className='quanlity-under-name'>Tiết kiệm</div>
                        <div className='quanlity-under-desc'>Cam kết giá rẻ nhất và hỗ trợ<br/> khi khách hàng liện hệ lại</div>
                    </div>
                    <div className='col-6 col-md-3 quanlity-under-content'>
                        <img src={Business} alt='icon' className='quanlity-under-icon'/>
                        <div className='quanlity-under-name'>Trọn gói</div>
                        <div className='quanlity-under-desc'>Khách biết giá trước khi đi và<br/>có dịch vụ chờ</div>
                    </div>
                    <div className='col-6 col-md-3 quanlity-under-content'>
                        <img src={Like} alt='icon' className='quanlity-under-icon'/>
                        <div className='quanlity-under-name'>Chất lượng xe</div>
                        <div className='quanlity-under-desc'>Xe đời mới - Sang trọng hơn</div>
                    </div>
                    <div className='col-6 col-md-3 quanlity-under-content'>
                        <img src={Safe} alt='icon' className='quanlity-under-icon'/>
                        <div className='quanlity-under-name'>di chuyển an toàn</div>
                        <div className='quanlity-under-desc'>Tài xế giàu kinh nghiệm,<br/>chạy Tour được</div>
                    </div>
                </div>
                <hr style={{border:'1px solid black'}}/>
                <div className='content-quanlity'>“Chúng tôi luôn xem trọng dịch vụ mà chúng tôi mang lại là đặt xe nhanh chóng – giá thành cạnh tranh luôn hấp dẫn so với thị trường xe tour, xe đường dài và luôn đặt sự an toàn của quý khách là ưu tiên hàng đầu.”</div>
                <hr style={{border:'1px solid black', marginBottom:'40px'}}/>
                </div>
            </div>
        </div>
        </>
    );
}

export default Index;
